<template>
  <div class="smvp-login-container-flex">
    <div class="smvp-login-grid">
      <div class="smvp-item-logo">
        <img src="/saasmvp-stacked-light.png" width="500" height=""/>
        <!--
        <picture>
        <source srcset="/saasmvp-stacked-light.png" media="all"> 
        <img src="/saasmvp-stacked-dark.png" width="500" height=""/> 
        </picture>
         -->
      </div>
      <div class="smvp-item-login">  
          <div class="smvp-theme-flex">
            <span class="theme-tooltip">
              <input @click="toggleTheme" type="checkbox" id="theme-toggle" class="theme-input"/> 
              <label for="theme-toggle" class="theme-label"></label>
            </span>
          </div>     
          <input v-model="credentials.username" id="username" type="text" class="form-control mb-3" placeholder="User Name">
          <input  v-model="credentials.password" id="password" type="password" class="form-control mb-3" placeholder="Password">
          <div class="alert" id="login-message"></div>
          <button @click="login" type="button" class="btn btn-primary">Login</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import { hashSync } from "bcrypt-ts"
const router = useRouter()
const runtimeConfig = await useRuntimeConfig()
const credentials = ref({username: "", password: ""})

const login = async () => {
  let isUserInDatabase = false
  //check if username, password in database
  try {
    const response = await fetch ('/api/v1/saasmvp-login', {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-TOKEN": runtimeConfig.public.saasmvpRestApiToken
      },
      body: JSON.stringify(credentials.value)
    })
    const json = await response.json()
    if(json.status == 200){
      isUserInDatabase = true
    }
  }catch(error){
    console.log(error)
  }
  
  if (isUserInDatabase) {
    if ((await smvpGetOAuthToken(credentials.value.username, credentials.value.password)) == 200) {
      router.push("dashboard")
    } else {
      resultMessage("Invalid Credentials", false)
    }
  }else{
    resultMessage("Invalid Credentials", false)
  }  
}

// Display result
const resultMessage = (message, flag) => {
  let el =  document.getElementById('login-message');
  if(flag){
    //success
    el.classList.remove("alert-danger");
    el.classList.add("alert-success");
  }else{
    //failure
    el.classList.remove("alert-success");
    el.classList.add("alert-danger");
  }
  el.innerHTML = message;
  el.style = "display: block"
}

const clearResultMessage = () => {
  let el =  document.getElementById('login-message');
  el.innerHTML = "";
  el.style = "display: none"
}

const toggleTheme = () =>{
    if(document.documentElement.getAttribute('smvp-theme') == 'dark'){
      setThemeLight()
    }else{
      setThemeDark()
    }
  }
  
  const setThemeLight = () => {
    document.documentElement.setAttribute('smvp-theme','light')
    document.getElementById('username').style.color="var(--vp-c-text-1)"
    //updatePictureTheme('light')
    setFocus()
  }

  const setThemeDark = () => {
    document.documentElement.setAttribute('smvp-theme','dark')
    document.getElementById('username').style.color="var(--vp-c-text-1)"
    document.getElementById('password').style.color="var(--vp-c-text-1)"
    //updatePictureTheme('dark')
    setFocus()
  }

  //updates <picture> elements to display dark image or light image
  const updatePictureTheme = (theme) => {
    const pictures = document.querySelectorAll('picture')
    pictures.forEach((picture) => {
      const sources=picture.querySelectorAll('source')
      sources.forEach((source) => {
        //"dark" == "all", "light" == "none"
        if(theme == "dark"){
          source.media= "all";
        }else{
          source.media="none"
        }
      })
    })
  };

const setFocus = () => {
  document.getElementById('username').focus();
  document.getElementById('username').setSelectionRange(0, 0, "none");
}

onMounted(() => {
  setFocus()
  clearResultMessage()
  setThemeLight()
})

</script>

<style>
.smvp-login-container-flex{
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: var( --vp-c-bg-soft);
}

.smvp-logo-box {
  width: 500px;
  height: 500px;
  background-image: url("saasmvp-stacked-light.png");
  background-size: contain;
}

.smvp-login-grid {
  display: grid;
  grid-template-columns: 2;
  grid-template-rows: 1;
  grid-template-areas: "logo" "login";
  padding: 1rem;
  column-gap: 1rem;
  border: 1px solid var(--vp-c-border);
  border-radius: 1rem;
}

.smvp-item-logo {
  grid-area: logo;
  grid-column: 1;
  grid-row: 1;
  padding: 1rem;
}

.smvp-item-login {
  grid-area: login;
  grid-column: 2;
  grid-row: 1;
  padding: 1rem;
  border: 1px solid var(--vp-c-border);
  border-radius: 1rem;
}

.smvp-theme-flex {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
}

/** theme toggle button **/
.hb-theme-label,
.theme-label {
  width: 3rem; 
  height: 1.5rem; 
  position: relative;
  display: block;
  /* sun background */
  background: var(--vp-c-gray-3); 
  border-radius: 2rem; 
  border: 1.5px solid var(--vp-c-border);
  cursor: pointer;
  transition: 0.3s; 
}

.hb-theme-label:hover,
.theme-label:hover {
  border: 1px solid var(--menu-item-selected-color);
}

.hb-theme-label:after,
.theme-label:after {
  /* sun */
  content: "";
  width: 1.2rem; 
  height: 1.2rem; 
  position: absolute;
  top: .1rem; 
  left: .1rem; 
  background: white;
  background-position: center;
  background-repeat: no-repeat;
  /* https://yoksel.github.io/url-encoder/ */
  background-image: url("data:image/svg+xml,%3Csvg width='16px' height='16px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 3V4M12 20V21M4 12H3M6.31412 6.31412L5.5 5.5M17.6859 6.31412L18.5 5.5M6.31412 17.69L5.5 18.5001M17.6859 17.69L18.5 18.5001M21 12H20M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z' stroke='%233c3c43c7' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  border-radius: 1.5rem; 
  transition: 0.3s; 
}

.hb-theme-input,
.theme-input {
  display: none;
}

.hb-theme-label-input:checked + .hb-theme-label,
.theme-input:checked + .theme-label {
  /* moon background */
  background: var(--vp-c-gray-3); 
}

.hb-theme-input:checked + .hb-theme-label:after,
.theme-input:checked + .theme-label:after {
  /** moon */
  left: 2.8rem; 
  transform: translateX(-100%);
  background: black; 
  background-position: center;
  background-repeat: no-repeat;
  /* https://yoksel.github.io/url-encoder/ */
  background-image: url("data:image/svg+xml,%3Csvg width='16px' height='16px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.32031 11.6835C3.32031 16.6541 7.34975 20.6835 12.3203 20.6835C16.1075 20.6835 19.3483 18.3443 20.6768 15.032C19.6402 15.4486 18.5059 15.6834 17.3203 15.6834C12.3497 15.6834 8.32031 11.654 8.32031 6.68342C8.32031 5.50338 8.55165 4.36259 8.96453 3.32996C5.65605 4.66028 3.32031 7.89912 3.32031 11.6835Z' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

/** theme button tooltip **/
.theme-tooltip {
  position: relative; /* making the .tooltip span a container for the tooltip text */
  color: gray;
}

.theme-tooltip:before {
  content: var(--tooltip-theme-text); 
  position: absolute;
  top: 1.5rem;
  transform: translateX(1.5rem);
  width: 150px;
  padding: 0;
  font-size: .75rem;
  border: 1px solid var(--vp-c-gray-3); 
  border-radius: 5px;
  background: white;
  text-align: center;
  display: none; 
}

.theme-tooltip:hover:before {
  display: block;
}


.form-control {
  background-color: var( --vp-c-bg-soft);
  border: 1px solid var(--vp-c-border);
}

.form-control:focus {
  background-color: var( --vp-c-bg-soft);
}

:root {
  --vp-c-text-1: rgba(60, 60, 67);  /* #3c3c43 */
  --vp-c-bg-soft: #f6f6f7;
  --vp-c-gray-3: #ebebef;
  --vp-c-border: #c2c2c4; 
  --menu-item-selected-color: #3e63dd;
  --tooltip-theme-text: "Switch to dark theme";
}

:root[smvp-theme="dark"] {
  --vp-c-text-1: rgba(255, 255, 245, 0.86); /* #fffff5db */
  --vp-c-bg-soft: #202127;
  --vp-c-gray-3: #32363f;
  --vp-c-border: #3c3f44;
  --menu-item-selected-color: #a8b1ff;
  --tooltip-theme-text: "Switch to light theme";
}
</style>