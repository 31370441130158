<template>
  <div><Login /></div>
</template>

<script setup>
const options = {
    logFlag: true,
    redirectRoute: "/", 
  };
  smvpInitAuth(options)
</script>

<style></style>